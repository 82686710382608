/** @type {import('tailwindcss').Config} */
import { wedgesTW } from '@lemonsqueezy/wedges'
import { gray, white, band, red, yellow, green, orange, pink, blue, themableColors } from '@haiper/colors'
const fontSizes = {
  xxs: ['0.625rem', { lineHeight: '0.75rem' }], // 10px, 12px
  xs: ['0.75rem', { lineHeight: '1rem' }], // 12px, 16px
  sm: ['0.875rem', { lineHeight: '1.25rem' }], // 14px, 20px
  base: ['1rem', { lineHeight: '1.5rem' }], // 16px, 24px
  lg: ['1.125rem', { lineHeight: '1.5rem' }], // 18px, 24px
  xl: ['1.25rem', { lineHeight: '1.75rem' }], // 20px, 28px
  '2xl': ['1.5rem', { lineHeight: '2rem' }], // 24px, 32px
  '3xl': ['1.75rem', { lineHeight: '2.25rem' }], // 28px, 36px
  '4xl': ['2rem', { lineHeight: '2.5rem' }], // 32px, 40px
  '4.5xl': ['2.25rem', { lineHeight: '2.75rem' }], // 36px, 44px
  '5xl': ['2.5rem', { lineHeight: '3rem' }], // 40px, 48px
  '6xl': ['3rem', { lineHeight: '3.5rem' }], // 48px, 56px
}

const tailwindConfig = {
  darkMode: ['class'],
  content: [
    './pages/**/*.{ts,tsx}',
    './components/**/*.{ts,tsx}',
    './constants/**/*.{ts,tsx}',
    './hooks/**/*.{ts,tsx}',
    './app/**/*.{ts,tsx}',
    './src/**/*.{ts,tsx}',
    './node_modules/@lemonsqueezy/wedges/dist/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    container: {
      center: true,
      // padding: '2rem',
      screens: {
        // generate a set of tailwind screens, from sm to 12xl
        sm: '640px',
        md: '1000px',
        lg: '1102px',
        xl: '1280px',
        '2xl': '1448px',
        '4xl': '1624px',
        '5xl': '1728px',
        // '7xl': '1928px',
      },
    },
    fontSize: {
      ...fontSizes,
      'heading-6xl': fontSizes['6xl'],
      'heading-5xl': fontSizes['5xl'],
      'heading-4.5xl': fontSizes['4.5xl'],
      'heading-4xl': fontSizes['4xl'],
      'heading-3xl': fontSizes['3xl'],
      'heading-2xl': fontSizes['2xl'],
      'heading-xl': fontSizes['xl'],
      'heading-lg': fontSizes['lg'],
      'heading-md': fontSizes['base'],
      'heading-sm': fontSizes['sm'],
      'heading-xs': fontSizes['xs'],
      'body-lg': fontSizes['base'],
      'body-md': fontSizes['sm'],
      'body-sm': fontSizes['xs'],
      'body-xs': fontSizes['xxs'],
    },
    linearBorderGradients: {
      colors: {
        'purple-pink': ['#690303', '#A000EB'],
        'blue-green': ['#0417BF', '#0D852F'],
      },
    },
    extend: {
      maxWidth: {
        'calc-100vw-minus-134px': 'calc(100vw - 134px)',
        'calc-100vw-minus-64px': 'calc(100vw - 64px)',
        'calc-100vw-minus-32px': 'calc(100vw - 32px)',
        'calc-100vh-minus-208px-16/9': 'calc((100vh - 208px) * 16 / 9)',
      },
      maxHeight: {
        'calc-100vh-minus-208px': 'calc(100vh - 208px)',
      },
      flexBasis: {
        '1/7': '14.2857143%',
        '2/7': '28.5714286%',
        '3/7': '42.8571429%',
        '4/7': '57.1428571%',
        '5/7': '71.4285714%',
        '6/7': '85.7142857%',
      },
      flexGrow: {
        0.5: 0.5,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
      },
      scale: {
        163: '1.63',
      },
      boxShadow: {
        xs: '0 1px 2px 0 rgba(18, 18, 23, 0.05)',
      },
      spacing: {
        9: '2.25rem',
        13: '3.25rem',
        15: '3.75rem',
        17: '4.25rem',
        18: '4.5rem',
        19: '4.75rem',
        21: '5.25rem',
        22: '5.5rem',
        25: '6.25rem',
        26: '6.5rem',
        30: '7.5rem',
        31: '7.75rem',
        42: '10.5rem',
        45: '11.25rem',
        49: '12.25rem',
        50: '12.5rem',
        66: '16.5rem',
        68: '17rem',
        70: '17.5rem',
        90: '22.5rem',
        100: '25rem',
        105: '26.25rem',
        120: '30rem',
        140: '35rem',
        160: '40rem',
        sm: '0.375rem', // 6px
      },
      aspectRatio: {
        '16/9': '16/9',
        '4/3': '4/3',
        '1/1': '1/1',
        '3/4': '3/4',
        '9/16': '9/16',
        '21/9': '21/9',
        '7/3': '7/3',
      },
      letterSpacing: {
        15: '-0.15px',
        32: '-0.32px',
        45: '-0.45px',
      },
      colors: {
        input: 'var(--input)',
        ring: 'var(--ring)',
        background: 'var(--surface)',
        foreground: 'var(--text)',
        'black-opacity-15': 'rgba(0, 0, 0, 0.15)',
        'gray-900-opacity-80': 'rgba(18, 18, 23, 0.8)',
        primary: band,
        secondary: gray,
        accent: band,
        muted: gray,
        destructive: red,
        gray,
        white,
        band,
        red,
        yellow,
        green,
        orange,
        pink,
        blue,
        'wg-gray': gray,
        'wg-white': white,
        'wg-purple': band,
        'wg-red': red,
        'wg-yellow': yellow,
        'wg-green': green,
        'wg-orange': orange,
        'wg-pink': pink,
        'wg-blue': blue,
        icon: {
          info: 'var(--icon-info)',
          caution: 'var(--icon-caution)',
          'critical-hover': 'var(--icon-critical-hover)',
          critical: 'var(--icon-critical)',
          success: 'var(--icon-success)',
          disable: 'var(--icon-disable)',
          'on-bright-color': 'var(--icon-on-bright-color)',
          'on-color': 'var(--icon-on-color)',
          'interactive-hover': 'var(--icon-interactive-hover)',
          interactive: 'var(--icon-interactive)',
          DEFAULT: 'var(--icon)',
          subdued: 'var(--icon-subdued)',
        },
        text: {
          'interactive-hover': 'var(--text-interactive-hover)',
          interactive: 'var(--text-interactive)',
          info: 'var(--text-info)',
          caution: 'var(--text-caution)',
          'critical-hover': 'var(--text-text-critical-hover)',
          critical: 'var(--text-text-critical)',
          success: 'var(--text-success)',
          'on-color': 'var(--text-on-color)',
          disabled: 'var(--text-disabled)',
          DEFAULT: 'var(--text)',
          subdued: 'var(--text-subdued)',
        },
        surface: {
          info: 'var(--surface-info)',
          caution: 'var(--surface-caution)',
          'critical-strong-hover': 'var(--surface-critical-strong-hover)',
          'critical-strong': 'var(--surface-critical-strong)',
          critical: 'var(--surface-critical)',
          success: 'var(--surface-success)',
          base: 'var(--surface-base)',
          'primary-disable': 'var(--surface-primary-disable)',
          'primary-hover': 'var(--surface-primary-hover)',
          primary: 'var(--surface-primary)',
          active: 'var(--surface-active)',
          'active-hover': 'var(--surface-active-hover)',
          hover: 'var(--surface-hover)',
          'on-video': 'var(--surface-on-video)',
          subdued: 'var(--surface-subdued)',
          semisubdued: 'var(--surface-semisubdued)',
          DEFAULT: 'var(--surface)',
        },
        border: {
          info: 'var(--border-info)',
          caution: 'var(--border-caution)',
          'critical-hover': 'var(--border-border-critical-hover)',
          critical: 'var(--border-border-critical)',
          success: 'var(--border-success)',
          disable: 'var(--border-disable)',
          active: 'var(--border-active)',
          hover: 'var(--border-hover)',
          button: 'var(--border-button)',
          DEFAULT: 'var(--border)',
        },
        avatar: {
          placeholder: 'var(--color-background-avatar-placeholder)',
        },
        mask: {
          from: 'var(--mask-from, transparent)',
          via: 'var(--mask-via, transparent)',
          to: 'var(--mask-to, transparent)',
        },
      },
      fontFamily: {
        moulin: ['Moulin', 'Moulin Trial', 'sans-serif'],
        sn: ['SN Pro', 'sans-serif'],
        mono: ['Roboto Mono', 'monospace'],
        instrument: ['Instrument Serif', 'serif'],
      },
      borderRadius: {
        '2.5xl': '1.25rem',
        sm: '0.375rem',
        md: '0.5rem',
        lg: '0.75rem',
        xl: '1rem',
      },
      keyframes: {
        'accordion-down': {
          from: { height: 0 },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: 0 },
        },
        'infinite-x': {
          '0%': { transform: 'translateX(0)' },
          '100%': { transform: 'translateX(-50%)' },
        },
        'infinite-x-reverse': {
          '0%': { transform: 'translateX(-50%)' },
          '100%': { transform: 'translateX(0)' },
        },
        'infinite-x-lighting': {
          '0%': { transform: 'translateX(-200%) skewX(15deg)' },
          '100%': { transform: 'translateX(200%) skewX(15deg)' },
        },
        'infinite-y-reverse': {
          '0%': { transform: 'translateY(-50%)' },
          '100%': { transform: 'translateY(0)' },
        },
      },
      height: {
        25: '6.25rem',
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        'spin-medium': 'spin 1.5s linear infinite',
        'infinite-x': 'infinite-x 60s linear infinite',
        'infinite-x-lighting': 'infinite-x-lighting 5s linear infinite',
        'infinite-y': 'infinite-y 60s linear infinite',
        'infinite-x-reverse': 'infinite-x-reverse 60s linear infinite',
        'infinite-y-reverse': 'infinite-y-reverse 60s linear infinite',
      },
      opacity: {
        8: '0.08',
        15: '0.15',
        33: '0.33',
        85: '0.85',
      },
      transitionDuration: {
        250: '250ms',
      },
      lineHeight: {
        4.5: '1.125rem',
        5.5: '1.375rem',
      },
      screens: {
        xs: '528px',
        md: '1000px',
        semi: '875px',
        tablet: '750px',
        laptop: '1244px',
        desktop: '1270px',
        widescreen: '1448px',
        xl: '1342px',
        '2xl': '1448px',
        '4xl': '1624px',
        '5xl': '1728px',
      },

      // override default typography styles
      typography: {
        DEFAULT: {
          css: {
            img: {
              marginTop: 0,
              marginBottom: 0,
            },
            a: {
              color: 'inherit',
              textDecoration: 'none',
              fontWeight: 'inherit',
              '&:hover': {
                textDecoration: 'underline',
              },
            },
            video: {
              marginTop: 0,
              marginBottom: 0,
            },
          },
        },
      },
      zIndex: {
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        60: 60,
        70: 70,
        80: 80,
        90: 90,
        100: 100,
      },
      backgroundImage: {
        'gradient-to-ri': 'linear-gradient(94deg, var(--tw-gradient-stops))',
      },
    },
  },
  plugins: [
    require('tailwindcss-animate'),
    require('@tailwindcss/typography')({
      className: 'haiper',
    }),
    require('@tailwindcss/container-queries'),
    // require('tailwindcss-3d'),
    // tailwindcss-3d(without legacy mode) is not working with low-version browsers, it translates 'translate-x-1/2' to 'tranlate: xxx' instead of 'transform: translateX(50%)'
    require('tailwindcss-3d')({ legacy: true }),
    require('tailwindcss-border-gradients')(),
    wedgesTW({
      prefix: 'hp', // prefix used for CSS variables
      defaultTheme: 'light', // default theme
      defaultExtendTheme: 'light', // default theme to extend when creating custom themes
      fontSmooth: 'antialiased', // specify font smoothing for Wedges components
      themes: {
        light: {
          colors: themableColors.light, // light themable colors
        },
        dark: {
          colors: themableColors.dark, // dark themable colors
        },
        // ... custom themes
      },
    }),
  ],
  // https://github.com/tailwindlabs/tailwindcss/discussions/1739#discussioncomment-3630717
  future: {
    hoverOnlyWhenSupported: true,
  },
}

export default tailwindConfig
